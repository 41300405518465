import { useCallback, useMemo, useState } from 'react';
import { InputDecorator } from '../InputDecorator';
import ReactSelect from 'react-select';
import {
  FormIsacIndustryViewProps,
  IsacHierarchySelectViewProps,
  IsacIndustryWithDecoratorViewProps,
  UBTFormIsacIndustryViewProps,
} from './types';
import { NoteWrapper } from '../InputDecorator/styles';
import { checkExist, Icon, Text } from 'UIComponents';
import { FieldContainer } from '../FieldContainer';
import { selectComponents } from '../Select/components';
import { useFormContext } from 'Context';
import { SingleValue } from 'react-select';
import { SelectOption } from '../Select/types';
import { useFormFieldController } from './controller';
import { StateManagedSelect } from '../Select/components/types';

const Component = ReactSelect as StateManagedSelect

export function IsacHierarchySelect({
  error,
  disabled,
  onChange,
  value,
  menuPortalTarget,
  menuPlacement,
  options,
  type,
  ...props
}: IsacHierarchySelectViewProps) {
  const [data, setOptions] = useState<{ options: SelectOption[], isLoading: boolean }>({
    options: [],
    isLoading: false
  });
  const core = useFormContext()

  const currentValue = useMemo(() => {
    switch (type) {
      case 'section':
        return value?.section ? { label: value.section, value: value.section } : null
      case 'division':
        return value?.division ? { label: value.division, value: value.division } : null
      case 'group':
        return value?.group ? { label: value.group, value: value.group } : null
      case 'klass':
        return value?.klass ? { label: value?.klass, value: value.value } : null
      default:
        return null
    }
  }, [type, value])

  const restrictionWarning = useMemo(() => {
    const valuesForRestrict = options?.selectRestrictions?.values;
    const warning = options?.selectRestrictions?.warning;

    if ((valuesForRestrict ? valuesForRestrict.length === 0 : true) || !value) {
      return null;
    } else {
      const values = Array.isArray(value) ? value : [value];
      return checkExist(valuesForRestrict, values) ? warning : null;
    }
  }, [options, value]);

  const params = useMemo(() => {
    if (type === 'klass') {
      return { section: value?.section, division: value?.division, group: value?.group }
    } else {
      let defaultParams: any = { type }
      if (type === 'division') {
        defaultParams = { ...defaultParams, section: value?.section }
      } else if (type === 'group') {
        defaultParams = { ...defaultParams, section: value?.section, division: value?.division }
      }
      return defaultParams
    }
  }, [type, value])

  const loadOptions = useCallback(async () => {
    if (type === 'klass') {
      const options = await core.loadIsacIndustries(params)
      if (options) {
        setOptions(prev => ({ options: options.map((item) => ({ label: item.klass, value: item.code })), isLoading: false }));
      }
      return
    }
    const options = await core.loadIsacHierarchy(params)
    if (options) {
      setOptions(prev => ({ options: options.map((item) => ({ label: item, value: item })), isLoading: false }));
      return
    }
  }, [params, core, type])

  const maybeLoadOptions = useCallback(async () => {
    setOptions(prev => ({ options: [], isLoading: true }))
    loadOptions()
  }, [loadOptions]);

  const onChangeValue = useCallback((option: SingleValue<SelectOption>) => {
    if (type === 'klass') {
      onChange?.({ ...value, loading: false, klass: option?.label, value: option?.value })
    } else if (type === 'group') {
      onChange?.({ ...value, loading: false, group: option?.value, klass: undefined, value: undefined })
    } else if (type === 'division') {
      onChange?.({ ...value, loading: false, division: option?.value, group: undefined, klass: undefined, value: undefined })
    } else if (type === 'section') {
      onChange?.({ loading: false, section: option?.value, division: undefined, group: undefined, klass: undefined, value: undefined })
    }
  }, [value, onChange, type])

  return (
    <>
      <Component
        {...props}
        value={currentValue as any}
        className="basic-single"
        classNamePrefix="select"
        components={selectComponents}
        isDisabled={disabled}
        onFocus={maybeLoadOptions}
        menuPlacement="auto"
        menuPosition="absolute"
        isSearchable
        options={data.options}
        error={!!error}
        isLoading={value?.loading || data.isLoading}
        onChange={onChangeValue as any}
      />
      {Boolean(restrictionWarning) && (
        <NoteWrapper>
          <Icon glyph="triangleWarning" color="warning-text-color" size={12} />
          <Text
            text={restrictionWarning}
            color="warning-text-color"
            size={12}
          />
        </NoteWrapper>
      )}
    </>
  );
}

export function IsacHierarchySelectWithDecorator({
  label,
  error,
  ...props
}: IsacIndustryWithDecoratorViewProps) {
  return (
    <InputDecorator label={label} error={error} {...props}>
      <IsacHierarchySelect error={!!error} {...props} />
    </InputDecorator>
  );
}

export function UBTFormIsacHierarchy({
  value,
  isVisible,
  field,
  error,
}: UBTFormIsacIndustryViewProps) {

  return (
    <FieldContainer hidden={!isVisible} halfWidth={field.options?.half_width}>
      <IsacHierarchySelectWithDecorator
        label={`${field.name} - Section`}
        value={value}
        placeholder='Select section'
        error={!value?.section ? error : undefined}
        options={field.options}
        type="section"
      />
      <IsacHierarchySelectWithDecorator
        label={`${field.name} - Division`}
        value={value}
        placeholder='Select division'
        disabled={!value?.section}
        error={!value?.division ? error : undefined}
        options={field.options}
        type="division"
      />
      <IsacHierarchySelectWithDecorator
        label={`${field.name} - Group`}
        value={value}
        placeholder='Select group'
        disabled={!value?.division}
        error={!value?.group ? error : undefined}
        options={field.options}
        type="group"
      />
      <IsacHierarchySelectWithDecorator
        label={`${field.name} - Class`}
        value={value}
        placeholder='Select class'
        disabled={!value?.group}
        error={!value?.value ? error : undefined}
        options={field.options}
        type="klass"
      />
    </FieldContainer>
  );
}

export function IsacHierarchy({ field }: FormIsacIndustryViewProps) {
  const { ref, value, onChange, error, label } = useFormFieldController(field);

  return (
    <FieldContainer
      ref={ref}
      hidden={!field.isVisible}
      halfWidth={field.options?.half_width}
    >
      <IsacHierarchySelectWithDecorator
        label={`${label} - Section`}
        value={value}
        onChange={onChange}
        placeholder='Select section'
        error={!value?.section ? error : undefined}
        options={field.options}
        type="section"
      />
      <IsacHierarchySelectWithDecorator
        label={`${label} - Division`}
        value={value}
        onChange={onChange}
        placeholder='Select division'
        disabled={!value?.section}
        error={!value?.division ? error : undefined}
        options={field.options}
        type="division"
      />
      <IsacHierarchySelectWithDecorator
        label={`${label} - Group`}
        value={value}
        onChange={onChange}
        placeholder='Select group'
        disabled={!value?.division}
        error={!value?.group ? error : undefined}
        options={field.options}
        type="group"
      />
      <IsacHierarchySelectWithDecorator
        label={`${label} - Class`}
        value={value}
        placeholder='Select class'
        onChange={onChange}
        disabled={!value?.group}
        error={!value?.value ? error : undefined}
        options={field.options}
        type="klass"
      />
    </FieldContainer>
  );
}
