import { axiosClient, axiosCustomerClient } from 'Api';
import {
  ApiSendMeta,
  ApiSendSubmission,
  ApiUBTEvent,
  FormInvitationResponse,
  FormResponse,
  SubmissionResponse,
  UploadedFileResponse,
  Watermark,
} from 'aid-form-service';
import { ApiClientDelegate } from 'aid-form-service/lib/ApiClient/index.delegate';
import { AxiosInstance } from 'axios';
import dayjs from 'dayjs';
import { cutFileName } from './utils';
import { Maybe } from 'aid-form-service/lib/utils';
import { IsacValue } from 'aid-form-service/lib/State/StateControllers/FieldControllers/IsacFieldController/index.types';

class CApiClient implements ApiClientDelegate {
  /**
   * The Axios instance used for making HTTP requests to the API.
   */
  private axiosInstance: AxiosInstance;

  /**
   * @description
   * On initialization sets the url.
   *
   * @param url.
   *
   * @returns An instance of this class.
   */
  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  /**
   * Requesting a form from an api.
   *
   * @param id - Form id.
   * @returns
   * Axios response with form.
   */
  async loadForm(id: string): Promise<FormResponse['data']> {
    return await this.axiosInstance
      .get(`/core/public/forms/${id}`)
      .then((data) => data?.data?.data);
  }

  /**
   * Requesting a form invitation from an api.
   *
   * @param id - Form invitation id.
   * @returns
   * Axios response with form invitation.
   */
  async loadFormInvitation(
    id: string
  ): Promise<FormInvitationResponse['data']> {
    return await this.axiosInstance
      .get(`/core/public/form_invitations/${id}`)
      .then((data) => data?.data?.data);
  }

  /**
   * Requesting a submission from an api.
   *
   * @param id - Submission id.
   * @returns
   * Axios response with submission.
   */
  async loadSubmission(id: string): Promise<SubmissionResponse['data']> {
    return await this.axiosInstance
      .get(`/core/public/submissions/${id}`)
      .then((data) => data?.data?.data);
  }

  /**
   * Sending UBT Event to the api.
   *
   * @param data - UBT Event.
   */
  async sendEvent(data: ApiUBTEvent) {
    try {
      await this.axiosInstance.post(`/data-pulse/public/events`, data);
    } catch (e) {
      return;
    }
  }

  /**
   * Sending a submission to the api.
   *
   * @param data - Object to save.
   * @param id - Submission id.
   *
   * @returns
   * Axios response with submission.
   */
  async sendSubmission(
    data: ApiSendSubmission,
    id?: string
  ): Promise<SubmissionResponse['data']> {
    if (id) {
      return await this.axiosInstance
        .put(`/core/public/submissions/${id}`, data)
        .then((data) => data.data.data);
    } else {
      return await this.axiosInstance
        .post(`/core/public/submissions`, data)
        .then((data) => data.data.data);
    }
  }

  /**
   * Sending a submission meta to the api.
   *
   * @param id - Submission id.
   * @param data - Object with meta information to save.
   *
   * @returns
   * Axios response with submission.
   */
  async saveSubmissionMeta(
    id: string,
    data: ApiSendMeta
  ): Promise<SubmissionResponse['data']> {
    return await this.axiosInstance
      .put(`/core/public/submissions/${id}/set_meta`, data)
      .then((data) => data?.data?.data);
  }

  async uploadFile(
    kind: string,
    file: File,
    watermark?: Maybe<Watermark>
  ): Promise<UploadedFileResponse['data']> {
    const formData = new FormData();
    const fileName =
      cutFileName(file.name) ||
      `${kind}-${dayjs().format('YYYY-MM-DD-HH-mm-ss')}`;
    formData.append('file_name', fileName);
    formData.append('kind', kind);
    formData.append('service_name', 'core');
    formData.append('file_size', file.size.toString());
    formData.append('is_watermark_required', `${!!watermark?.enabled}`);
    if (watermark?.value) {
      formData.append('watermark_text', watermark.value);
    }

    const res = await this.axiosInstance.post(
      `/documents/upload/safe`,
      formData
    );

    if (res?.data?.success && res?.data?.data?.safe_upload_url) {
      const uploadUrl = res?.data?.data?.safe_upload_url;
      if (uploadUrl) {
        let headers = {};
        if (uploadUrl.includes('x-amz-server-side-encryption')) {
          headers = {
            'x-amz-server-side-encryption': 'AES256',
          };
        }
        const uploadToSafeLink = await fetch(uploadUrl, {
          method: 'PUT',
          body: file,
          headers,
        });

        if (uploadToSafeLink.status === 200) {
          return res?.data?.data;
        } else {
          throw new Error(uploadToSafeLink.status.toString());
        }
      }
    }
    return;
  }

  loadFile(id: string): Promise<UploadedFileResponse['data']> {
    return this.axiosInstance
      .get(`/documents/downloads/${id}`)
      .then((data) => data?.data?.data);
  }

  loadIsac(value: string, client_id: string): Promise<IsacValue> {
    return this.axiosInstance
      .get(`/core/public/dictionaries/isac_industries`, {
        params: { code: value, client_id },
      })
      .then((res): IsacValue => {
        const first = res?.data?.data?.[0];

        if (first) {
          return {
            value: first.code,
            klass: first.klass,
            section: first.section,
            division: first.division,
            group: first.group,
            loading: false,
          };
        }
        return { loading: false };
      });
  }

  cachedHierarchy: Record<string, string[]> = {};

  async loadIsacHierarchy(params: any, client_id: string): Promise<string[]> {
    const key = JSON.stringify({ ...params, client_id });
    if (this.cachedHierarchy[key]) {
      return this.cachedHierarchy[key];
    }
    const data = await this.axiosInstance
      .get(`/core/public/dictionaries/isac_hierarchy`, {
        params: { ...params, client_id },
      })
      .then((res) => res?.data?.data);
    this.cachedHierarchy[key] = data;
    return data;
  }

  cachedcIndustries: Record<string, any[]> = {};

  async loadIsacIndustries(params: any, client_id: string): Promise<any[]> {
    const key = JSON.stringify({ ...params, client_id });
    if (this.cachedcIndustries[key]) {
      return this.cachedcIndustries[key];
    }
    const data = await this.axiosInstance
      .get(`/core/public/dictionaries/isac_industries`, {
        params: { ...params, client_id },
      })
      .then((res) => res?.data?.data);
    this.cachedcIndustries[key] = data;
    return data;
  }
}

class AApiClient implements ApiClientDelegate {
  /**
   * The Axios instance used for making HTTP requests to the API.
   */
  private axiosInstance: AxiosInstance;

  /**
   * @description
   * On initialization sets the url.
   *
   * @param url.
   *
   * @returns An instance of this class.
   */
  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  /**
   * Requesting a form from an api.
   *
   * @param id - Form id.
   * @returns
   * Axios response with form.
   */
  async loadForm(id: string): Promise<FormResponse['data']> {
    return await this.axiosInstance
      .get(`/core/public/forms/${id}`)
      .then((data) => data?.data?.data);
  }

  /**
   * Requesting a form invitation from an api.
   *
   * @param id - Form invitation id.
   * @returns
   * Axios response with form invitation.
   */
  async loadFormInvitation(
    id: string
  ): Promise<FormInvitationResponse['data']> {
    return await this.axiosInstance
      .get(`/core/public/form_invitations/${id}`)
      .then((data) => data?.data?.data);
  }

  /**
   * Requesting a submission from an api.
   *
   * @param id - Submission id.
   * @returns
   * Axios response with submission.
   */
  async loadSubmission(id: string): Promise<SubmissionResponse['data']> {
    return await this.axiosInstance
      .get(`/core/public/submissions/${id}`)
      .then((data) => data?.data?.data);
  }

  /**
   * Sending UBT Event to the api.
   *
   * @param data - UBT Event.
   */
  async sendEvent(data: ApiUBTEvent) {
    try {
      await this.axiosInstance.post('/data-pulse/public/events', data);
    } catch (e) {
      return;
    }
  }

  /**
   * Sending a submission to the api.
   *
   * @param data - Object to save.
   * @param id - Submission id.
   *
   * @returns
   * Axios response with submission.
   */
  async sendSubmission(
    data: ApiSendSubmission,
    id?: string
  ): Promise<SubmissionResponse['data']> {
    if (id) {
      return await this.axiosInstance
        .put(`/core/public/submissions/${id}`, data)
        .then((data) => data.data.data);
    } else {
      return await this.axiosInstance
        .post(`/core/public/submissions`, data)
        .then((data) => data.data.data);
    }
  }

  /**
   * Sending a submission meta to the api.
   *
   * @param id - Submission id.
   * @param data - Object with meta information to save.
   *
   * @returns
   * Axios response with submission.
   */
  async saveSubmissionMeta(
    id: string,
    data: ApiSendMeta
  ): Promise<SubmissionResponse['data']> {
    return await this.axiosInstance
      .put(`/core/public/submissions/${id}/set_meta`, data)
      .then((data) => data?.data?.data);
  }

  async uploadFile(
    kind: string,
    file: File,
    watermark?: Maybe<Watermark>
  ): Promise<UploadedFileResponse['data']> {
    const formData = new FormData();
    const fileName =
      cutFileName(file.name) ||
      `${kind}-${dayjs().format('YYYY-MM-DD-HH-mm-ss')}`;
    formData.append('file_name', fileName);
    formData.append('kind', kind);
    formData.append('service_name', 'core');
    formData.append('file_size', file.size?.toString());
    formData.append('is_watermark_required', `${!!watermark?.enabled}`);
    if (watermark?.value) {
      formData.append('watermark_text', watermark.value);
    }

    const res = await this.axiosInstance.post(
      `/documents/upload/safe`,
      formData
    );

    if (res?.data?.success && res?.data?.data?.safe_upload_url) {
      const uploadUrl = res?.data?.data?.safe_upload_url;
      if (uploadUrl) {
        let headers = {};
        if (uploadUrl.includes('x-amz-server-side-encryption')) {
          headers = {
            'x-amz-server-side-encryption': 'AES256',
          };
        }
        const uploadToSafeLink = await fetch(uploadUrl, {
          body: file,
          headers,
        });

        if (uploadToSafeLink.status === 200) {
          return res?.data?.data;
        } else {
          throw new Error(uploadToSafeLink.status.toString());
        }
      }
    }
    return;
  }

  loadFile(id: string): Promise<UploadedFileResponse['data']> {
    return this.axiosInstance
      .get(`/documents/downloads/${id}`)
      .then((data) => data?.data?.data);
  }

  loadIsac(value: string, client_id: string): Promise<IsacValue> {
    return this.axiosInstance
      .get(`/core/public/dictionaries/isac_industries`, {
        params: { code: value, client_id },
      })
      .then((res): IsacValue => {
        const first = res?.data?.data?.[0];

        if (first) {
          return {
            value: first.code,
            klass: first.klass,
            section: first.section,
            division: first.division,
            group: first.group,
            loading: false,
          };
        }
        return { loading: false };
      });
  }

  cachedHierarchy: Record<string, string[]> = {};

  async loadIsacHierarchy(params: any, client_id: string): Promise<string[]> {
    const key = JSON.stringify({ ...params, client_id });
    if (this.cachedHierarchy[key]) {
      return this.cachedHierarchy[key];
    }
    const data = await this.axiosInstance
      .get(`/core/public/dictionaries/isac_hierarchy`, {
        params: { ...params, client_id },
      })
      .then((res) => res?.data?.data);
    this.cachedHierarchy[key] = data;
    return data;
  }

  cachedcIndustries: Record<string, any[]> = {};

  async loadIsacIndustries(params: any, client_id: string): Promise<any[]> {
    const key = JSON.stringify({ ...params, client_id });
    if (this.cachedcIndustries[key]) {
      return this.cachedcIndustries[key];
    }
    const data = await this.axiosInstance
      .get(`/core/public/dictionaries/isac_industries`, {
        params: { ...params, client_id },
      })
      .then((res) => res?.data?.data);
    this.cachedcIndustries[key] = data;
    return data;
  }
}

export const CustomerApiClient = new CApiClient(axiosCustomerClient);
export const AccountApiClient = new AApiClient(axiosClient);
