import { FieldControllerDelegate } from 'aid-form-service/lib/State/StateControllers/FieldControllers/FieldController/index.delegate';
import { Error } from 'aid-form-service/lib/State/StateControllers/index.types';
import { Maybe } from 'aid-form-service/lib/utils/index.types';
import { useFormObjectController } from 'Hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';

export function useFormFieldController(field: FieldControllerDelegate) {
  const { ref } = useFormObjectController(field);
  const [value, setValue] = useState(field.value);
  const [error, setError] = useState(field.error);
  const label = useMemo(
    () => (!field.options?.hide_label ? field.name : undefined),
    [field.options?.hide_label, field.name]
  );

  const onChange = useCallback(
    (value) => {
      if (value.value) {
        field.value = value;
      } else if (field.value && !value.value) {
        field.value = { loading: false };
      }
      setValue(value);
    },
    [field]
  );

  useEffect(() => {
    const updateValue = function (nextValue: any) {
      setValue((prev: any) => {
        console.log(prev, nextValue);
        if (prev.loading !== nextValue.loading) {
          return nextValue;
        }
        return prev;
      });
    };
    field.subscribe('value', updateValue);
    const updateError = function (error: Maybe<Error>) {
      setError(error);
    };
    field.subscribe('error', updateError);
    return () => {
      field.unsubscribe('value', updateValue);
      field.unsubscribe('error', updateError);
    };
  }, [field]);

  return { ref, onChange, value, error, label };
}
